export * from 'cf-core/src/images'
export const Hero404 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1582791158/general/404Hero.png'
export const appStore =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/app-store.png'
export const cftitle =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/cftitle.png'
export const googlePlay =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/google-play.png'
export const user =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/user.png'
export const sharedBg =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1582514489/general/marblebgdefault.jpg'
export const orderStatusProgress =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1554602154/general/OrderStatusProgress.png'
export const orderStatusMeter =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1554666730/general/OrderStatusMeter.png'
export const promoHeader =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/promoHeader.png'
export const restaurant =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/v1562129124/Shaolin/restaurant.jpg'
export const yelp =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/v1601869832/general/yelp_white.png'
export const facebook =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/v1601869832/general/facebook_white.png'
export const zomato =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/v1601869833/general/zomato_white.png'
export const instagram =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/v1601869833/general/instagram_white.png'
export const contactMobilebg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/contactMobilebg.jpg'
export const rewardbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardbg.jpg'
export const downloadButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/downloadButton.png'
export const flowerTL =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/flowerTL.png'
export const flowerBR =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/flowerBR.png'
export const redeemButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/redeemButton.png'
export const pointsMeter =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/pointsMeter.png'
export const rewardArrowLeft =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardArrowLeft.png'
export const rewardArrowRight =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardArrowRight.png'
export const rewardDefault =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardDefault.png'
export const viewMenu =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1582589981/Kaido/viewMenu.png'
export const cflogo =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1590261442/general/cflogo.png'
export const broadwayAbout =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1652399854/kameigroup/about.jpg'
export const richmondAbout =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1652460521/kameigroup/richmondAbout.jpg'
export const contactbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1652395133/kameigroup/footer.jpg'
export const hero =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1652395133/kameigroup/hero.jpg'
export const heroText =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1619644468/sushi-jo/heroText.png'
export const logo =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1652395133/kameigroup/logo.png'
export const broadwayMobileAbout =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1652403636/kameigroup/mobileAbout.jpg'
export const richmondMobileAbout =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1652460344/kameigroup/mobileAboutRichmond.jpg'
export const mobileContactbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1652464175/kameigroup/mobileFooter.jpg'
export const mobileHero =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1643863140/victoriasushi/mobileHero.jpg'
export const mobileMap =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1652403332/kameigroup/mobileMap.jpg'
export const mobileHeroText =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1618520629/sushi-jo/mobileHeroText.png'
export const broadwayMobilePromotion =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1680535937/kameigroup/mobilePromo6.jpg'
export const richmondMobilePromotion =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1652463506/kameigroup/mobilePromoRichmond.jpg'
export const orderDeliveryButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1652395466/kameigroup/reserveButton.png'
export const orderPickupButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1652395411/kameigroup/orderButton.png'
export const broadwayPromotions =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1680540584/kameigroup/promotions6.jpg'
export const richmondPromotions =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1652463506/kameigroup/richmondPromo.jpg'
export const broadwayFoodGallery =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1680540423/kameigroup/foodGallery3.jpg'
export const broadwayMobileGallery =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1666064025/kameigroup/mobileGallery4.jpg'
export const richmondFoodGallery =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1652462023/kameigroup/richmondGallery.jpg'
export const richmondMobileGallery =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1652461946/kameigroup/mobileGalleryRichmond.jpg'
export const mobileLogo =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1643864099/victoriasushi/mobileLogo.png'
export const mobileMapRichmond =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1652404637/kameigroup/mobileMapRich.jpg'
export const locationMapBroadway =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1652395133/kameigroup/locationMap.jpg'
export const locationMapRichmond =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1652404322/kameigroup/locationMapRich.jpg'
export const mobileLocationsBg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1652464411/kameigroup/mobileLocationPick.jpg'
export const lunch =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1663958235/kameigroup/lunch2.jpg'
export const lunchText =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1652400719/kameigroup/lunchText.png'
export const dinner =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1652396634/kameigroup/dinner.jpg'
export const dinnerText =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1652400922/kameigroup/dinnerText.png'
export const happyHour =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1652396635/kameigroup/happyHour.jpg'
export const happyHourText =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1652400922/kameigroup/happyHourT.png'
export const drink =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1652396635/kameigroup/drink.jpg'
export const drinkText =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1652400922/kameigroup/drinkText.png'
export const press =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1680540343/kameigroup/Press.jpg'
export const mobilePress =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1680542293/kameigroup/mobilePress.jpg'
export const dineOutButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1706406915/kameigroup/dineOutButton.webp'
export const seasonalButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1706406915/kameigroup/seasonalButton.webp'

export { default as richmondLunch } from './richmondLunch.pdf'
export { default as richmondDinner } from './richmondDinner.pdf'
export { default as richmondDrink } from './richmondDrink.pdf'
export { default as richmondHappyHour } from './richmondHappyHour.pdf'
export { default as broadwayLunch } from './broadwayLunch.pdf'
export { default as broadwayDinner } from './broadwayDinner.pdf'
export { default as broadwayDrink } from './broadwayDrink.pdf'
export { default as broadwayHappyHour } from './kameiHappyHour03312025.pdf'
export { default as dineOutMenu } from './dineOutMenu.pdf'
export { default as seasonalMenu } from './seasonalMenu.jpeg'
export { default as dailySpecial } from './dailySpecial.jpg'
